.question-header {
  border-radius: 5px;
  padding: 0.5rem 1.25rem;
  &:hover {
    cursor: pointer;
    background: rgba(239, 108, 0, 0.12);
  }
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 90%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  &.difficulty-0 {
    background: #ffebee;
    border: 2px solid #ffebee;
  }

  &.difficulty-1 {
    background: #ef9a9a;
    border: 2px solid #ef9a9a;
  }
  &.difficulty-2 {
    background: #ef5350;
    border: 2px solid #ef5350;
  }
  &.difficulty-3 {
    background: #e53935;
    border: 2px solid #e53935;
  }
  &.difficulty-4 {
    background: #b71c1c;
    border: 2px solid #b71c1c;
  }
}

.question-difficulty {
  .MuiSvgIcon-root {
    font-size: 1rem;
    vertical-align: middle;
  }
}
