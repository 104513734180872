.radio-price-selected {
  background-color: rgb(255, 193, 7);
}

.radio-price {
  background-color: #6c757d;
  color: white;
}

.price-normal-size {
  font-size: 1.5rem;
}
.price-big-size {
  font-size: 2rem;
}
