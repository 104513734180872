.MuiChip-root.inprogress {
  &,
  &:hover {
    background: #f9faf8;
  }
}
.cardTopic {
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
  .MuiBox-root {
    background: #f6f6f7;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

// .MuiChip-root.done {
//   background: #ccc;
// }
