// .intro {
//   .hero--image-background {

//   }
// }
.pink {
  text-decoration: underline;
  color: rgb(240, 0, 87);
  cursor: pointer;
}
