.footer-entretien-tech {
  text-align: center;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.075);
  padding-bottom: 2rem;
}

.footer-entretien-tech p {
  margin: 0.375rem;
  font-size: 0.875rem;
}
