.student-card {
  transition: all 0.1s linear;
  &:hover {
    transform: translateY(-8px);
    margin-top: -8px;
    border: 1px solid #ccc;
  }
  // .MuiSvgIcon-root {
  //   height: 70px;
  //   width: 70px;
  // }
  .student-avatar {
    .MuiSvgIcon-root {
      height: 30px;
      width: 30px;
    }
  }
}
