$bg-width: 770px;
$bg-height: 600px;
$bg-url: "../../app/images/programming-languages.jpeg";

@keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}

@keyframes bg-scrolling {
  0% {
    // background-position: $bg-width $bg-height;
    background-position: 50% 100%;
  }
}

.hero {
  .hero--wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    max-width: 1236px;
  }
  .hero--title {
    font-size: 3.25rem;
    line-height: 1.2;
    // font-family: Inter, sans-serif;
  }

  .hero--right-side {
    flex: 0 0 50%;
    position: relative;
    max-width: 50%;
  }

  .hero--image {
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    shape-outside: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    left: 0%;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .hero--image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .hero--cover {
    width: 50vw;
    height: 100%;
    position: relative;
  }

  .hero--image-background {
    top: -25%;
    left: -100%;
    width: 770%;
    height: 600%;
    position: absolute;
    // animation: jss145 50s linear infinite;
    animation: bg-scrolling 100s linear infinite;
    // background: url(https://assets.maccarianagency.com/the-front/web-screens/home/home-hero-bg-light.png);
    // background: url($bg-url) repeat 0 0;
    background: url("https://images.assetsdelivery.com/compings_v2/annaleni/annaleni1612/annaleni161200084.jpg")
      repeat 0 0;
    background-size: 800px auto;
    background-color: #e8eaf6;
    background-repeat: repeat;
    background-attachment: scroll;
  }

  .divider-landing {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.hero {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
