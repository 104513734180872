.MuiDialog-paperScrollPaper {
  max-height: 100%;
}

.paypal-button-number-1 {
  border: 1px solid red !important;
}

.unlock-lockicon {
  width: 1rem;
}

.coupon input {
  height: 1.5em;
  width: 6em;
  text-align: center;
  background: transparent;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}
