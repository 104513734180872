/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
/* .App {
  background: linear-gradient(to right, #ada996, #f2f2f2, #dbdbdb, #eaeaea);
} */

input:focus {
  outline: none;
}

.App-Header {
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 15px 0 rgb(0 0 0 / 12%);
}
