.social-button {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #c2c2c2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 16px;
  height: 40px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.social-button {
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 16px;
  vertical-align: text-bottom;
}

.social-button.google {
  background-color: #fff;
  color: #dc4e41;
}

.social-button .SVGInline-svg {
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  vertical-align: -1px;
}

.social-button span.label {
  flex: auto;
  display: inline-block;
  padding: 0;
  vertical-align: 3px;
  line-height: 1em;
  outline: none;
  width: 100%;
  margin-left: -20px;
}

.signup-modal {
  width: 100%;
}

.signup-modal input {
  width: 100%;
}

.signup-modal .input-signup {
  width: 100%;
  margin-bottom: 1rem;
}

.orSeparator {
  width: 100%;
}

.orSeparator .borderTop {
  border: 1px solid #c4c7cc;
  margin: 1rem 0;
}

.w-100pct {
  width: 100%;
}

.orText {
  vertical-align: 5px;
  margin: 2px;
}

.forgotten-password {
  text-align: center;
}
.modal-footer-register {
  margin: 0;
  width: 100%;
  text-align: center;
}

#facebook-icon-login {
  position: absolute;
  left: 1rem;
}

#login-modal .facebook-login-button {
  width: 100%;
  margin-top: 0.5rem;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 1.5;
  height: 2.5rem;
}
