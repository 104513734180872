#signin:hover {
  background-color: rgba(0, 0, 0, 0);
}

.MuiAppBar-colorPrimary {
  /* background: linear-gradient(to right, #ada996, #f2f2f2, #dbdbdb, #eaeaea); */
  background-color: white;
}

.MuiButton-containedSecondary {
  background-color: #bbaaaa;
}
.MuiButton-containedSecondary:hover {
  background-color: #ccaaaa;
}
